import './index.css'
import MainLayout from './pages/MainLayout'
import UnderMaintenance from './pages/UnderMaintenance'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

export const App = () => {
  return (
      <BrowserRouter>
        <div className="relative">
          <Routes>
            <Route>
              <Route
                path="/"
                element={
                  <MainLayout>
                    <UnderMaintenance />
                  </MainLayout>
                }
              />
              <Route
                path="*"
                element={
                  <MainLayout>
                    <Navigate to="/" />
                  </MainLayout>
                }
              />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
  )
}

export default App
