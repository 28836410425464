import { Typography } from "../../components"
import Button from "../../components/Button"
import { Link } from "react-router-dom"
import SocialLinks from "../Common/SocialLinks"

const GetInTouch = () => {
    return <div className="col-span-1 grid grid-cols-1 gap-1 lg:pl-40 2xl:pl-60 max-sm:order-3 max-sm:place-items-center">
    <Typography size={'xl'} type={'bold'} className="pt-36 max-lg:pt-10">
        GET IN TOUCH
    </Typography>
    <Typography size={'md'} type={'light'} className="mb-8 text-secondaryWhite">
        Call us to inquire now about
        admissions and more!
    </Typography>
    
    <Button
      text={'89089 39039'}
      className={'lg:w-3/4 md:w-full mb-4 max-sm:hidden'}
      size={'lg'}
      background={'transparent'}
      isSubmitButton={true}
      isButtonDisable={false}
    > <Typography size={'sm'} type="regular" className="py-2 lg:py-3"><Link to="tel:8908939039" target="_new" className="mb-4">89089 39039</Link></Typography>
    </Button>

    <Button
      text={'89089 39039'}
      className={'w-3/4 mb-4 md:hidden'}
      size={'lg'}
      background={'fill'}
      isSubmitButton={true}
      isButtonDisable={false}
    > <Typography size={'md'} type="regular" className="py-2 lg:py-3"><Link to="tel:8908939039" target="_new" className="mb-4">89089 39039</Link></Typography>
    </Button>

    <Button
      text={'98099 14444'}
      className={'lg:w-3/4 md:w-full mb-4 max-sm:hidden'}
      size={'lg'}
      background={'transparent'}
      isSubmitButton={true}
      isButtonDisable={false}
    > <Typography size={'sm'} type="regular" className="py-2 lg:py-3"><Link to="tel:9809914444" target="_new" className="mb-4">98099 14444</Link></Typography>
    </Button>

    <Button
      text={'98099 14444'}
      className={'w-3/4 mb-4 md:hidden'}
      size={'lg'}
      background={'fill'}
      isSubmitButton={true}
      isButtonDisable={false}
    > <Typography size={'md'} type="regular" className="py-2 lg:py-3"><Link to="tel:9809914444" target="_new" className="mb-4">98099 14444</Link></Typography>
    </Button>
   

    <Typography size={'sm'} type={'regular'} className="underline mb-4">
    <Link to="mailto:contact@spinlaunch.one" target='_blank'
    rel='noopener noreferrer'>contact@spinlaunch.one</Link>
    </Typography>
    <div className="2xl:pr-28 w-full"><SocialLinks /></div> 
    <Typography size={'sm'} type={'light'} className="text-secondaryWhite">
    Address:
    </Typography>
    <Typography size={'sm'} type={'light'} className="text-secondaryWhite">
    1st Floor, Ganesham Square, Kakatiya Hills
    Road No.7, Kavuri Hills Phase 3, Madhapur
    Hyderabad, Telangana, India 500033
    </Typography>  
    </div>
}

export default GetInTouch