import { Link } from "react-router-dom"
import { FacebookIcon, InstaIcon, WhatsappIcon, LinkedinIcon } from "../../images"


const SocialLinks = () => {
    return ( <div className="grid grid-cols-4 w-full my-4 max-sm:place-items-center">
    <Link to="https://api.whatsapp.com/send?phone=918908939039" target='_blank'
    rel='noopener noreferrer'><img src={WhatsappIcon} alt="whatsapp" /></Link>
     <Link to="https://www.facebook.com/profile.php?id=61552301699466" target='_blank'
    rel='noopener noreferrer'><img src={FacebookIcon} alt="facebook" /></Link>
     <Link to="https://instagram.com/spinlaunch.one?igshid=OGQ5ZDc2ODk2ZA==" target='_blank'
    rel='noopener noreferrer'><img src={InstaIcon} alt="instagram" /></Link>
    <Link to="https://www.linkedin.com/company/spin-launch" target='_blank'
    rel='noopener noreferrer'><img src={LinkedinIcon} alt="linkedin" /></Link>
    </div>)
}

export default SocialLinks