import axios from 'axios'

const API_URL = process.env.API_URL ? process.env.API_URL : ''

export const post_download_brochure_form = async (receiver: string) => {
  try {
    const payload = {
      type: 'download_brochure_form',
      data: {"email": receiver, "source": "website"},
    }
    const response = await axios.post(API_URL + '/send-email/', payload, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    return response.data
  } catch (error: any) {
    if (error.response) {
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    } else if (error.request) {
      console.log(error.request)
    } else {
      console.log('Error', error.message)
    }
    return
  }
}