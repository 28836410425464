import React from 'react'
import { Typography } from "../../components"
import { ComingSoonBg, Logo, Spin1Logo } from "../../images"
import Copyright from "../Common/CopyRight"
import ContentWrapper from "../../ContentWrapper"
import DownloadForm from './downloadForm'
import GetInTouch from './GetInTouch'
import RocketGrid from './RocketGrid'

const UnderMaintenance: React.FC = () => {
    return (<ContentWrapper className="items-center h-full">
      <div className="grid grid-col-1 gap-1 place-items-center max-sm:text-center md:mx-10">
        <img src={Logo} alt="logo" className="lg:w-2/5 md:w-3/5 pt-4 max-sm:w-full" />
        <DownloadForm />
        <Typography
          size={'sm'}
          type={'light'}
          className="leading-snug tracking-[12.07px] max-sm:tracking-[5px] text-center drop-shadow-xl mt-16 shadow-[#53FFEA] bg-auto bg-origin-border bg-center"
          style={{
            backgroundImage: `url(${ComingSoonBg})`,
            backgroundRepeat: 'no-repeat'
        }}
        >
          WEBSITE LAUNCHING SOON
        </Typography>
      
        <div className={`grid md:grid-cols-3 max-sm:grid-cols-1 pb-20 place-items-start`}>
            <GetInTouch />
            <RocketGrid />
        </div>
        <Copyright />
        </div>
        </ContentWrapper>)
}

export default UnderMaintenance