import axios from 'axios'

const API_URL = process.env.API_URL ? process.env.API_URL : ''

export const post_new_user = async (emailid: string) => {
    try {
      const response = await axios.post(API_URL + '/users/', {"email": emailid, "name": "", "mobile": "", "location" : "", "source": "website", "is_active": true, "unsubscribe": false}, {
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
      return response.data ? { status: 200 } : {}
      // return true
    } catch (error: any) {
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log('Error', error.message)
      }
      return false
    }
  }