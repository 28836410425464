interface TypographyI {
  children?: any
  size: string
  className?: string
  type: 'light' | 'regular' | 'medium' | 'semi' | 'bold'
  color?: 'white' | 'default'
  fontFamily?: string
  isGradientText?: boolean
  style?: any
}

const Typography = (props: TypographyI) => {
  const {
    children,
    size,
    className,
    type,
    color,
    fontFamily = 'font-montserrat',
    isGradientText,
    style
  } = props

  const getFontVariant = () => {
    let clsName = ''
    switch (type) {
      case 'light':
        clsName = 'font-light'
        break
      case 'regular':
        clsName = 'font-normal '
        break
      case 'medium':
        clsName = 'font-medium '
        break
      case 'semi':
        clsName = 'font-semibold '
        break
      case 'bold':
        clsName = 'font-bold '
        break
      default:
        break
    }
    return clsName
  }

  const getClassName = () => {
    let clsName = ''
    switch (size) {
      case 'xxs':
        clsName = 'text-[9px]' //font-size: /* 12px */'
        break
      case 'xs':
        clsName = 'text-xs' //font-size: /* 12px */'
        break
      case 'sm':
        clsName = 'text-sm' //font-size: /* 14px */'
        break
      case 'md':
        clsName = 'text-sm lg:text-base' //font-size: /* 16px */'
        break
      case 'lg':
        clsName = 'text-lg' //font-size: /* 18px */'
        break
      case 'xl':
        clsName = 'text-xl' //font-size: /* 20px */'
        break
      case '2xl':
        clsName = 'text-sm lg:text-2xl' //font-size: /* 24px */'
        break
      case '3xl':
        clsName = 'text-3xl' //font-size: /* 30px */'
        break
      case '4xl':
        clsName = 'text-xl lg:text-4xl' //font-size: /* 36px */'
        break
      case '5xl':
        clsName = 'text-2xl lg:text-5xl' //font-size: /* 48px */'
        break
      case '6xl':
        clsName = 'text-2xl lg:text-[52px] leading-tight	' //font-size: /* 52px */'
        break
      case '7xl':
        clsName = 'text-2xl lg:text-[54px] leading-tight' //font-size: /* 54px */'
        break
      case '8xl':
        clsName = 'text-2xl lg:text-[66px]' //font-size: /* 66px */'
        break
      case '9xl':
        clsName = 'text-5xl lg:text-[80px]' //font-size: /* 66px */'
        break

      default:
        clsName = size //font-size: /* 54px */'
        break

        break
    }
    return clsName
  }

  return (
    <>
      <div
        className={`${className} ${fontFamily} ${getFontVariant()} ${getClassName()} ${
          color === 'white' ? 'text-white' : ''
        } ${isGradientText ? 'text-gradient' : ''}`} style={style}
      >
        {children}
      </div>
    </>
  )
}

export default Typography
