
interface IContentWrapper {
  children?: any
  className: string
}

const ContentWrapper = (props: IContentWrapper) => {
  const { children, className } = props
  return (<div className={`text-white md:px-[6%] ${className}`}>{children}</div>)
}

export default ContentWrapper
